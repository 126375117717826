import { makeStyles } from '@material-ui/core/styles';
import theme from "../config/Theme";
import { stylesConfig } from "../config/StylesConfig";

//Since we are using material ui I wanna try useStyles hook
const useStyles = makeStyles(theme => ({
    //Layout
    layoutWidth: {
        width: stylesConfig.menuWidth,
    },
    //MenuItem
    menuButton:{
        color: "white",
        padding: 20,
        "&:hover, &:focus, &$selected, &$selected:hover": {
            backgroundColor: theme.palette.primary.light,
        }
    },
        selected:{
            backgroundColor: theme.palette.primary.light,
            borderRight: 5,
            borderColor: 'white',
            borderStyle: 'solid',
        },
    menuButtonIcon:{
        minWidth: 30,
        color: 'white',
    },
    menuButtonText:{
        fontWeight: 'bold'
    },
}),{defaultTheme: theme});

export { useStyles };

