import { makeStyles } from '@material-ui/core/styles';
import theme from "../config/Theme";
import { stylesConfig } from "../config/StylesConfig";

//Since we are using material ui I wanna try useStyles hook
const useStyles = makeStyles(theme => ({
    //Layout
    layoutRoot: {
        display: "flex",
        alignItems: "center"
    },
    layoutWidth: {
        width: stylesConfig.menuWidth,
    },
    layoutMenu: {
        width: stylesConfig.menuWidth,
        background: theme.palette.primary.main,
        border: 0,
        overflowX: "hidden"
    },
    layoutChildren: {
        padding: stylesConfig.contentPadding,
        minWidth: 750,
        width: "100%"
    },
    layoutFooter: {
        marginTop: "auto"
    },
    layoutLogo:{
        padding: 25,
        background: 'white'
    },
}),{defaultTheme: theme});

export { useStyles };

