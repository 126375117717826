import { Drawer, CssBaseline } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FolderIcon from '@material-ui/icons/Folder';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import NoteIcon from '@material-ui/icons/Note';
import React, { ReactNode, useEffect, useState } from 'react';
import { useUserContext } from '../context/UserContext';
import LanguageSwitcher from './LanguageSwitcher';
import { useStyles } from "../styles/LayoutStyles";
import { useTranslation } from 'react-i18next';

import MenuItem from './common/MenuItem'
import {navigate} from "gatsby";

import logo from "/static/images/conformityDocOpt1.png";
import cmilogoEN from "/static/images/cmi-en.png";
import cmilogoCS from "/static/images/cmi-cz.png";
import { Authority } from '../api/RoleManagementApi';

interface LayoutProps {
    children?: ReactNode,
    allowAnonymous: boolean;
}

export default function Layout(props: LayoutProps) {
    const { authorities, setLoggedInClient, loggedInClient, isAccessTokenExpired, logOut, logInExtended, refreshToken, loggedInOnBackend, activeCompany } = useUserContext();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if(!props.allowAnonymous && isAccessTokenExpired()){
            if(refreshToken!=null){
                logInExtended().then((res)=>{
                    if(res){
                        setLoggedInClient(true);
                    }else{
                        navigate(`/login?redirect=${location.pathname}`)
                    }
                })
            }else{
                navigate(`/login?redirect=${location.pathname}`)
            }
        }else{
            if(!props.allowAnonymous && !isAccessTokenExpired()){
                loggedInOnBackend().then(res => {
                    if(res) {
                        setLoggedInClient(true);
                    }else{
                        navigate(`/login?redirect=${location.pathname}`)
                    }
                })
            } else {
                setLoggedInClient(false)
                setLoading(false)
            }
        }
    },[]);

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    if(isLoading && !loggedInClient){
        return (
            <div>Loading...</div>
        )
    }
    return (
        <div className={classes.layoutRoot}>
        <CssBaseline />
        <Drawer anchor="left" open={true} variant="persistent" className={classes.layoutWidth} PaperProps={{elevation: 5}} classes={{
            paper: classes.layoutMenu
        }}>
            <img src={activeCompany==2 ? i18n.language=="cs" ? cmilogoCS : cmilogoEN : logo} className={classes.layoutLogo} alt="PortaMedica logo"/>
            <MenuItem link='/products' text={t('PRODUCTS')} icon={<FolderIcon/>}/>
            {authorities.includes(Authority.COMPANIES) && <MenuItem link='/companies' text={t('COMPANIES')} icon={<BusinessIcon/>}/>}
            <MenuItem link='/standards' text={t('STANDARDS')} icon={<NoteIcon/>}/>
            {authorities.includes(Authority.USER_MANAGEMENT) && <MenuItem link='/users' text={t('USERMANAGEMENT')} icon={<PeopleIcon/>}/>}
            <div className={classes.layoutFooter}>
                <hr/>
                <MenuItem link='/mfa/setup' text={t("MULTIFACTOR")} icon={<SettingsIcon/>}/>
                <MenuItem onClick={() => logOut()} link='/login' text={t('LOGOUT')} icon={<PowerSettingsNewIcon/>}/>
            </div>
        </Drawer>
        <div className={classes.layoutChildren}>
            {loggedInClient ? props.children : ""}
        </div>
        <LanguageSwitcher/>
    </div>
    );
}
