import APIKit from './Base'

export interface RoleDto{
    id?:number,
    name?:string,
    description?:string,
    roleType?:string,
    permissionDtos?:PermissionDto[],
}
export interface PermissionDto{
    permissionType?:string,
    identifiers?:number[],
}
export interface RoleDtoPage {
    content?:RoleDto[],
    totalElements?:number
}
export enum Authority{
    USER_MANAGEMENT="USER_MANAGEMENT", LINK_GROUP="LINK_GROUP", GENERATE_PDF="GENERATE_PDF", COMPANIES="COMPANIES"
}

export const getRoles = (page, size, filter) =>{
    return APIKit.get("/roles", { params: { page: page, size: size, filter: filter } })
        .then<RoleDtoPage>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}