import React from 'react'
import { ListItemIcon, ListItem, ListItemText, Typography} from "@material-ui/core";
import { Link } from 'gatsby';

import { useStyles } from "../../styles/MenuItemStyles";

interface MenuItemProps {
    icon?: any,
    text: string,
    link?: string,
    onClick?: any,
}

export default function MenuItem(props: MenuItemProps) {
    const classes = useStyles();

    return (<div className={classes.layoutWidth}>
        <Link to={props.link} style={{ textDecoration: 'none' }}>
        <ListItem
            button
            classes={{root: classes.menuButton, selected: classes.selected}}
            selected={location.pathname.startsWith(props.link)}
            onClick={props.onClick}
        >
            {props.icon!=null ? <ListItemIcon className={classes.menuButtonIcon}>{props.icon}</ListItemIcon> : null}
            <ListItemText primary={
                <Typography className={classes.menuButtonText}>
                    {props.text}
                </Typography>
            } />
        </ListItem>
        </Link>
    </div>);
}